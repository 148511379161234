import { combineReducers } from 'redux'

import modularGroup from './modular-group/reducers'
import shareholder from './shareholder/reducers'
import systemUser from './system-users/reducers'
import systemSettings from './settings/reducers'

export default combineReducers({
  modularGroup,
  shareholder,
  systemUser,
  systemSettings,
})
