import { combineReducers } from 'redux'

import depreciationMethod from './depreciation-method/reducers'
import depreciationPeriod from './depreciation-period/reducers'
import bloodGroup from './blood-group/reducers'
import revenueDistribution from './revenue-distribution/reducers'
import salaryPeriod from './salary-period/reducers'
import userRole from './user-role/reducers'
import userType from './user-type/reducers'
import profitDistribution from './profit-distribution-method/reducers'

export default combineReducers({
  depreciationMethod,
  depreciationPeriod,
  bloodGroup,
  revenueDistribution,
  salaryPeriod,
  userRole,
  userType,
  profitDistribution,
})
