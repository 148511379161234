import { all } from 'redux-saga/effects'

import mainUnit from './main-unit/sagas'
import unit from './unit/sagas'
import productGroup from './product-group/sagas'
import productSubGroup from './product-sub-group/sagas'
import productCategory from './product-category/sagas'
import productSubCategory from './product-sub-category/sagas'
import productBrand from './product-brand/sagas'
import productModel from './product-model/sagas'
import product from './product/sagas'
import packageGroup from './package-group/sagas'
import productPackage from './package/sagas'
import opening from './opening/sagas'

export default function* rootSaga() {
  yield all([
    mainUnit(),
    unit(),
    productGroup(),
    productSubGroup(),
    productCategory(),
    productSubCategory(),
    productBrand(),
    productModel(),
    product(),
    packageGroup(),
    productPackage(),
    opening(),
  ])
}
