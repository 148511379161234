import { all } from 'redux-saga/effects'

import account from './account/sagas'
import business from './business/sagas'
import coa from './coa/sagas'
import party from './party/sagas'
import product from './product/sagas'
import general from './general/sagas'
import others from './others/sagas'
import humanResources from './human-resources/sagas'

export default function* rootSaga() {
  yield all([
    business(),
    account(),
    coa(),
    party(),
    product(),
    general(),
    others(),
    humanResources(),
  ])
}
