const actions = {
  SET_STATE: 'settings/SET_STATE',
  UPDATE: 'settings/UPDATE',
  ADD: 'settings/ADD',

  GET_LIST: 'settings/GET_LIST',
  RESET_LIST: 'settings/RESET_LIST',

  RESET_SAVING_STATUS: 'settings/RESET_SAVING_STATUS',
}

export default actions
