import { all } from 'redux-saga/effects'

import modularGroup from './modular-group/sagas'
import shareholder from './shareholder/sagas'
import systemUser from './system-users/sagas'
import systemSettings from './settings/sagas'

export default function* rootSaga() {
  yield all([modularGroup(), shareholder(), systemUser(), systemSettings()])
}
