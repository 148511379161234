import { all } from 'redux-saga/effects'

import auth from './auth/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import systemSettings from './system-settings/sagas'
import registration from './registration/sagas'
import session from './session/sagas'
import vouchers from './vouchers/sagas'
import invoices from './invoices/sagas'
import reports from './reports/sagas'

export default function* rootSaga() {
  yield all([
    auth(),
    menu(),
    settings(),
    systemSettings(),
    registration(),
    session(),
    vouchers(),
    invoices(),
    reports(),
  ])
}
