const actions = {
  SET_STATE: 'rptstock/SET_STATE',

  GET_LIST_SFS: 'rptstock/GET_LIST_SFS',
  RESET_LIST: 'rptstock/RESET_LIST',
  GET_STOCK_MOVEMENT: 'rptstock/GET_STOCK_MOVEMENT',
  RESET_SAVING_STATUS: 'rptstock/RESET_SAVING_STATUS',
}

export default actions
