const actions = {
  SET_STATE: 'crv/SET_STATE',

  ADD: 'crv/ADD',
  UPDATE: 'crv/UPDATE',
  DELETE: 'crv/DELETE',

  GET_LIST: 'crv/GET_LIST',
  RESET_LIST: 'crv/RESET_LIST',

  GET_EDITABLE: 'crv/GET_EDITABLE',
  RESET_EDITABLE: 'crv/RESET_EDITABLE',

  GET_DETAILS: 'crv/GET_DETAILS',
  RESET_DETAILS: 'crv/RESET_DETAILS',

  // ADD_NEW_LOOKUP: 'crv/ADD_NEW_LOOKUP',
  // RESET_NEW_LOOKUP: 'crv/RESET_NEW_LOOKUP',

  // GET_LOOKUPS: 'crv/GET_LOOKUPS',
  // RESET_LOOKUPS: 'crv/RESET_LOOKUPS',

  RESET_SAVING_STATUS: 'crv/RESET_SAVING_STATUS',
}

export default actions
