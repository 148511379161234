import { all, call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import * as httpService from 'services/http'
import actions from './actions'

const endpoint = '/reports/stock'

export function* GET_LIST_SFS({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingList: true,
    },
  })
  const response = yield call(httpService.getItem, `${endpoint}/sfs`, queryParams)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        list: response.data.products,
        totalRecords: response.data.total_records,
        isLoadingList: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingList: false,
      },
    })
  }
}

export function* GET_STOCK_MOVEMENT({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingList: true,
    },
  })
  const response = yield call(httpService.getItem, `${endpoint}/stock_movement`, queryParams)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        list: response.data.products,
        totalRecords: response.data.total_records,
        isLoadingList: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingList: false,
      },
    })
  }
}

export function* RESET_LIST() {
  // yield delay(200)
  yield put({
    type: actions.SET_STATE,
    payload: {
      list: [],
      totalRecords: 0,
    },
  })
}

export function* RESET_SAVING_STATUS() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingStatus: '',
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_LIST_SFS, GET_LIST_SFS),
    takeLatest(actions.GET_STOCK_MOVEMENT, GET_STOCK_MOVEMENT),
    takeLatest(actions.RESET_LIST, RESET_LIST),
    takeLatest(actions.RESET_SAVING_STATUS, RESET_SAVING_STATUS),
  ])
}
