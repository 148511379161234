import axios from 'axios'
import store from 'store'
import { notification } from 'antd'

const apiClient = axios.create({
  // baseURL: 'https://api.fireprofit.xufire.com',
  // baseURL: 'https://xufire-profit-api.herokuapp.com',
  // baseURL: 'http://fireprofitapi-env.eba-ckhxtwhe.us-east-2.elasticbeanstalk.com',
  // baseURL: 'https://api.fireprofit.net',
  // baseURL: 'https://api.xufireprofit.com',
  // baseURL: 'https://api.chapter2.xufireprofit.com',
  // baseURL: 'http://localhost:3131',
  // baseURL: 'https://sandboxapi.xufireprofit.com',
  baseURL: 'https://profitapi.xufire.com',
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers['x-auth-token'] = accessToken
  }
  return request
})

apiClient.interceptors.response.use(
  response => {
    // Any status code of 2xx cause this function to trigger
    // Do something with response data
    return response.data
  },
  error => {
    // Any status codes outside of 2xx cause this function to trigger
    // Do something with response error
    const expectedError = error.response?.status >= 400 && error.response?.status < 500

    if (expectedError) {
      const { data } = error.response
      if (data && data.message && data.description) {
        notification.warning({
          message: data.message,
          description: data.description,
        })
      }
    } else {
      notification.warning({
        message: error.message,
        description: 'An unexpected error occurred.',
      })
    }

    return Promise.reject(error)
  },
)

export default apiClient
