import actions from './actions'

const initialState = {
  trialBalanceData: [],
  incomeStatementData: {},
  balanceSheetData: {},
  cashErrorData: [],
  bankErrorData: [],
  productErrorData: [],
  totalDebit: 0,
  totalCredit: 0,
  isErrorList: false,
  isLoadingList: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
