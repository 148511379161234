import { all, call, delay, put, select, takeLatest, takeEvery } from 'redux-saga/effects'
// import { all, call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
// import { notification } from 'antd'
import * as httpService from 'services/http'
import { notification } from 'antd'
import actions from './actions'

const endpoint = '/system_users'
const userEndpoint = '/users'

export function* ADD({ payload }) {
  const { item } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(httpService.addItem, endpoint, payload)
  if (response) {
    notification.success({
      message: 'SBU Users Added',
      description: 'Status: Successful',
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        savingStatus: 'success',
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

// export function* UPDATE({ payload }) {
//   const { id, item } = payload
//   yield put({
//     type: actions.SET_STATE,
//     payload: {
//       savingStatus: 'saving',
//     },
//   })
//   const response = yield call(httpService.updateItem, endpoint, id, item)
//   if (response) {
//     notification.success({
//       message: 'Modular Group Updated',
//       description: 'Status: Successful',
//     })
//     yield put({
//       type: actions.SET_STATE,
//       payload: {
//         savingStatus: 'success',
//       },
//     })
//   } else {
//     yield put({
//       type: actions.SET_STATE,
//       payload: {
//         savingStatus: '', // failed
//       },
//     })
//   }
// }

export function* DELETE({ payload }) {
  const { id } = payload
  const response = yield call(httpService.deleteItem, endpoint, id)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        totalRecords: 0,
      },
    })
    notification.success({
      message: 'SBU Users Removed',
      description: 'Status: Successful',
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {},
    })
  }
}

export function* GET_LIST({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingList: true,
    },
  })
  const response = yield call(httpService.getItem, endpoint, queryParams)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        list: response.data.users,
        totalRecords: response.data.total_records,
        isLoadingList: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingList: false,
      },
    })
  }
}

export function* GET_SBU_LIST({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: actions.SET_STATE,
    payload: {
      lookups: [],
      isLoadingLookups: true,
    },
  })
  const response = yield call(httpService.getLookup, endpoint, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        lookups: response.data.users,
        isLoadingLookups: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingLookups: false,
      },
    })
  }
}

export function* RESET_LIST() {
  // yield delay(200)
  yield put({
    type: actions.SET_STATE,
    payload: {
      list: [],
      totalRecords: 0,
    },
  })
}

export function* CHANGE_DROPDOWN_STATUS({ payload }) {
  const { id, item } = payload
  const { list } = yield select(state => state.systemSettings.systemUser)
  const newList = list.map(itm => {
    if (itm.id === id) {
      return { ...itm, status: item.status }
    }
    return itm
  })
  yield put({
    type: actions.SET_STATE,
    payload: {
      list: newList,
    },
  })
  const response = yield call(httpService.patchItem, userEndpoint, id, item)
  if (response) {
    notification.success({
      message: "User's Status Changed",
      description: 'Status: Successful',
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        list,
      },
    })
  }
}

export function* CHANGE_LOGIN_STATUS({ payload }) {
  const { id, item } = payload
  const { list } = yield select(state => state.systemSettings.systemUser)
  const newList = list.map(itm => {
    if (itm.id === id) {
      return { ...itm, status: item.login_status }
    }
    return itm
  })
  yield put({
    type: actions.SET_STATE,
    payload: {
      list: newList,
    },
  })
  const response = yield call(httpService.patchItem, userEndpoint, id, item)
  if (response) {
    notification.success({
      message: "User's Status Changed",
      description: 'Status: Successful',
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        list,
      },
    })
  }
}

export function* DISCONTINUE({ payload }) {
  const { id, patch } = payload
  const { list, totalRecords } = yield select(state => state.registration.humanResources.user)
  yield put({
    type: actions.SET_STATE,
    payload: {
      list: list.filter(item => item.id !== id),
      patchingStatus: 'patching',
    },
  })
  const response = yield call(httpService.patchItem, `${userEndpoint}/discontinue`, id, patch)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        totalRecords: Math.max(0, totalRecords - 1),
        patchingStatus: 'patched',
      },
    })
    notification.success({
      message: 'User Discontinued',
      description: 'Status: Successful',
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        list,
        patchingStatus: '', // failed
      },
    })
  }
}

export function* CHANGE_PASSWORD({ payload }) {
  console.log(payload)
  const { id } = payload
  const response = yield call(httpService.patchItem, `${userEndpoint}/change_password`, id, {
    new_password: payload.new_password,
  })
  if (response) {
    notification.success({
      message: 'Password changes',
      description: 'Status: Successful',
    })
  } else {
    notification.error({
      message: 'Error',
      description: 'Unable to change password',
    })
  }
}

export function* SEND_RESET_PASSWORD_LINK({ payload }) {
  const { id } = payload
  const response = yield call(httpService.patchItem, `${userEndpoint}/send_reset_pass_link`, id)
  if (response) {
    notification.success({
      message: 'Reset link sent',
      description: 'Status: Successful',
    })
  } else {
    notification.error({
      message: 'Error',
      description: 'Unable to send reset password link',
    })
  }
}

// export function* GET_EDITABLE({ payload }) {
//   const { id } = payload
//   yield put({
//     type: actions.SET_STATE,
//     payload: {
//       isLoadingEditable: true,
//     },
//   })
//   const response = yield call(httpService.getItemById, endpoint, id)
//   if (response && response.code === 200) {
//     yield put({
//       type: actions.SET_STATE,
//       payload: {
//         editable: response.data.modular_group,
//         isLoadingEditable: false,
//       },
//     })
//   } else if (response && response.code === 404) {
//     const { list, totalRecords } = yield select(state => state.modularGroup)
//     yield put({
//       type: actions.SET_STATE,
//       payload: {
//         list: list.filter(item => item.id !== id),
//         totalRecords: Math.max(0, totalRecords - 1),
//         isLoadingEditable: false,
//       },
//     })
//   } else {
//     yield put({
//       type: actions.SET_STATE,
//       payload: {
//         isLoadingEditable: false,
//       },
//     })
//   }
// }

// export function* RESET_EDITABLE() {
//   yield put({
//     type: actions.SET_STATE,
//     payload: {
//       editable: {},
//     },
//   })
// }

export function* GET_DETAILS({ payload }) {
  const { id } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingDetails: true,
    },
  })
  const response = yield call(httpService.getItemById, endpoint, id)
  if (response && response.code === 200) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        details: response.data.user,
        isLoadingDetails: false,
      },
    })
  } else if (response && response.code === 404) {
    const { list, totalRecords } = yield select(state => state.systemSettings.systemUser)
    yield put({
      type: actions.SET_STATE,
      payload: {
        list: list.filter(item => item.id !== id),
        totalRecords: Math.max(0, totalRecords - 1),
        isLoadingDetails: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingDetails: false,
      },
    })
  }
}

export function* RESET_DETAILS() {
  yield delay(200)
  yield put({
    type: actions.SET_STATE,
    payload: {
      details: {},
    },
  })
}

// export function* ADD_NEW_LOOKUP({ payload }) {
//   const { item } = payload
//   yield put({
//     type: actions.SET_STATE,
//     payload: {
//       savingStatus: 'saving',
//     },
//   })
//   const response = yield call(httpService.addItem, `${endpoint}/lookup`, item)
//   if (response) {
//     notification.success({
//       message: 'Modular Group Added',
//       description: 'Status: Successful',
//     })
//     const { lookups } = yield select(state => state.modularGroup)
//     yield put({
//       type: actions.SET_STATE,
//       payload: {
//         newLookup: response.data.modular_group,
//         lookups: [...lookups, response.data.modular_group],
//         savingStatus: 'success',
//       },
//     })
//   } else {
//     yield put({
//       type: actions.SET_STATE,
//       payload: {
//         savingStatus: '', // failed
//       },
//     })
//   }
// }

// export function* RESET_NEW_LOOKUP() {
//   yield put({
//     type: actions.SET_STATE,
//     payload: {
//       newLookup: {},
//       savingStatus: '',
//     },
//   })
// }

// export function* GET_LOOKUPS({ payload }) {
//   const queryParams = { ...payload }
//   yield put({
//     type: actions.SET_STATE,
//     payload: {
//       lookups: [],
//       isLoadingLookups: true,
//     },
//   })
//   const response = yield call(httpService.getLookup, endpoint, payload)
//   if (response) {
//     yield put({
//       type: actions.SET_STATE,
//       payload: {
//         lookups: response.data.users,
//         isLoadingLookups: false,
//       },
//     })
//   } else {
//     yield put({
//       type: actions.SET_STATE,
//       payload: {
//         isLoadingLookups: false,
//       },
//     })
//   }
// }

export function* RESET_LOOKUPS() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      lookups: [],
    },
  })
}

// export function* RESET_SAVING_STATUS() {
//   yield put({
//     type: actions.SET_STATE,
//     payload: {
//       savingStatus: '',
//     },
//   })
// }

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ADD, ADD),
    // takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.CHANGE_DROPDOWN_STATUS, CHANGE_DROPDOWN_STATUS),
    takeEvery(actions.CHANGE_LOGIN_STATUS, CHANGE_LOGIN_STATUS),
    takeLatest(actions.GET_SBU_LIST, GET_SBU_LIST),
    takeLatest(actions.GET_LIST, GET_LIST),
    takeLatest(actions.RESET_LIST, RESET_LIST),
    // takeLatest(actions.GET_EDITABLE, GET_EDITABLE),
    // takeLatest(actions.RESET_EDITABLE, RESET_EDITABLE),
    takeLatest(actions.GET_DETAILS, GET_DETAILS),
    takeLatest(actions.RESET_DETAILS, RESET_DETAILS),
    // takeLatest(actions.ADD_NEW_LOOKUP, ADD_NEW_LOOKUP),
    takeLatest(actions.DISCONTINUE, DISCONTINUE),
    takeLatest(actions.CHANGE_PASSWORD, CHANGE_PASSWORD),
    takeLatest(actions.SEND_RESET_PASSWORD_LINK, SEND_RESET_PASSWORD_LINK),
    // takeLatest(actions.RESET_NEW_LOOKUP, RESET_NEW_LOOKUP),
    // takeLatest(actions.GET_LOOKUPS, GET_LOOKUPS),
    // takeLatest(actions.RESET_LOOKUPS, RESET_LOOKUPS),
    // takeLatest(actions.RESET_SAVING_STATUS, RESET_SAVING_STATUS),
  ])
}
