const actions = {
  SET_STATE: 'accopening/SET_STATE',

  ADD: 'accopening/ADD',
  DELETE: 'accopening/DELETE',

  GET_LIST: 'accopening/GET_LIST',
  RESET_LIST: 'accopening/RESET_LIST',

  RESET_SAVING_STATUS: 'accopening/RESET_SAVING_STATUS',

  POST_ALL_BALANCE: 'accopening/POST_ALL_BALANCE',
}

export default actions
