import { combineReducers } from 'redux'

import user from './user/reducers'
import salaryUser from './salary-user/reducers'
import department from './department/reducers'

export default combineReducers({
  user,
  salaryUser,
  department,
})
