import { combineReducers } from 'redux'

import aag from './aag/reducers'
import account from './account/reducers'
import bankAccount from './bank-account/reducers'
import fixedAsset from './fixed-asset/reducers'
import capitalAccount from './capital-account/reducers'
import opening from './opening/reducers'
import fixedAssetSale from './fixed-asset-sale/reducers'

export default combineReducers({
  aag,
  account,
  bankAccount,
  fixedAsset,
  capitalAccount,
  opening,
  fixedAssetSale,
})
