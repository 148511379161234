import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import auth from './auth/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import systemSettings from './system-settings/reducers'
import registration from './registration/reducers'
import session from './session/reducers'
import vouchers from './vouchers/reducers'
import invoices from './invoices/reducers'
import reports from './reports/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    menu,
    settings,
    systemSettings,
    registration,
    session,
    vouchers,
    invoices,
    reports,
  })
