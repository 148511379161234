const actions = {
  SET_STATE: 'systemUser/SET_STATE',

  ADD: 'systemUser/ADD',
  UPDATE: 'systemUser/UPDATE',
  DELETE: 'systemUser/DELETE',
  DISCONTINUE: 'systemUser/DISCONTINUE',
  CHANGE_DROPDOWN_STATUS: 'systemUser/CHANGE_DROPDOWN_STATUS',
  CHANGE_LOGIN_STATUS: 'systemUser/CHANGE_LOGIN_STATUS',
  CHANGE_PASSWORD: 'systemUser/CHANGE_PASSWORD',
  SEND_RESET_PASSWORD_LINK: 'systemUser/SEND_RESET_PASSWORD_LINK',

  GET_LIST: 'systemUser/GET_LIST',
  RESET_LIST: 'systemUser/RESET_LIST',
  GET_SBU_LIST: 'systemUser/GET_SBU_LIST',

  GET_EDITABLE: 'systemUser/GET_EDITABLE',
  RESET_EDITABLE: 'systemUser/RESET_EDITABLE',

  GET_DETAILS: 'systemUser/GET_DETAILS',
  RESET_DETAILS: 'systemUser/RESET_DETAILS',

  ADD_NEW_LOOKUP: 'systemUser/ADD_NEW_LOOKUP',
  RESET_NEW_LOOKUP: 'systemUser/RESET_NEW_LOOKUP',

  GET_LOOKUPS: 'systemUser/GET_LOOKUPS',
  RESET_LOOKUPS: 'systemUser/RESET_LOOKUPS',

  RESET_SAVING_STATUS: 'systemUser/RESET_SAVING_STATUS',
}

export default actions
