const actions = {
  SET_STATE: 'jv/SET_STATE',

  ADD: 'jv/ADD',
  UPDATE: 'jv/UPDATE',
  DELETE: 'jv/DELETE',

  GET_LIST: 'jv/GET_LIST',
  RESET_LIST: 'jv/RESET_LIST',

  GET_EDITABLE: 'jv/GET_EDITABLE',
  RESET_EDITABLE: 'jv/RESET_EDITABLE',

  GET_DETAILS: 'jv/GET_DETAILS',
  RESET_DETAILS: 'jv/RESET_DETAILS',

  // ADD_NEW_LOOKUP: 'jv/ADD_NEW_LOOKUP',
  // RESET_NEW_LOOKUP: 'jv/RESET_NEW_LOOKUP',

  // GET_LOOKUPS: 'jv/GET_LOOKUPS',
  // RESET_LOOKUPS: 'jv/RESET_LOOKUPS',

  RESET_SAVING_STATUS: 'jv/RESET_SAVING_STATUS',
}

export default actions
