import { all, call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import * as httpService from 'services/http'
import { notification } from 'antd'
import actions from './actions'

const endpoint = '/day_end/report'

export function* GET_LIST({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingList: true,
    },
  })

  const response = yield call(httpService.getItem, endpoint, queryParams)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        trialBalanceData: response.data.trialBalance,
        incomeStatementData: response.data.incomeStatement,
        balanceSheetData: response.data.balanceSheet,
        totalDebit: response.data.totalDebit,
        totalCredit: response.data.totalCredit,
        isLoadingList: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingList: false,
      },
    })
  }
}

export function* RESET_LIST() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      trialBalanceData: [],
      incomeStatementData: {},
      balanceSheetData: {},
    },
  })
}

export default function* rootSaga() {
  yield all([takeLatest(actions.GET_LIST, GET_LIST), takeLatest(actions.RESET_LIST, RESET_LIST)])
}
