const actions = {
  SET_STATE: 'cpv/SET_STATE',

  ADD: 'cpv/ADD',
  UPDATE: 'cpv/UPDATE',
  DELETE: 'cpv/DELETE',

  GET_LIST: 'cpv/GET_LIST',
  RESET_LIST: 'cpv/RESET_LIST',

  GET_EDITABLE: 'cpv/GET_EDITABLE',
  RESET_EDITABLE: 'cpv/RESET_EDITABLE',

  GET_DETAILS: 'cpv/GET_DETAILS',
  RESET_DETAILS: 'cpv/RESET_DETAILS',

  // ADD_NEW_LOOKUP: 'cpv/ADD_NEW_LOOKUP',
  // RESET_NEW_LOOKUP: 'cpv/RESET_NEW_LOOKUP',

  // GET_LOOKUPS: 'cpv/GET_LOOKUPS',
  // RESET_LOOKUPS: 'cpv/RESET_LOOKUPS',

  RESET_SAVING_STATUS: 'cpv/RESET_SAVING_STATUS',
}

export default actions
