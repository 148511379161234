const actions = {
  SET_STATE: 'prodopening/SET_STATE',

  ADD: 'prodopening/ADD',
  DELETE: 'prodopening/DELETE',

  GET_LIST: 'prodopening/GET_LIST',
  RESET_LIST: 'prodopening/RESET_LIST',

  RESET_SAVING_STATUS: 'prodopening/RESET_SAVING_STATUS',

  POST_ALL_PRODUCTS: 'prodopening/POST_ALL_PRODUCTS',
}

export default actions
