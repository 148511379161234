import { combineReducers } from 'redux'

import pi from './purchase-invoices/purchase-invoice/reducers'
import pit from './purchase-invoices/purchase-tax-invoice/reducers'
import pri from './purchase-invoices/purchase-return-invoice/reducers'
import prit from './purchase-invoices/purchase-tax-return-invoice/reducers'
import si from './sale-invoices/sale-invoice/reducers'
import sit from './sale-invoices/sale-tax-invoice/reducers'
import sri from './sale-invoices/sale-return-invoice/reducers'
import srit from './sale-invoices/sale-tax-return-invoice/reducers'

export default combineReducers({
  pi,
  pit,
  pri,
  prit,
  si,
  sit,
  sri,
  srit,
})
